import CONSTANTS from "@/constants/index"

const validNumber = text => {
  const validRegex = CONSTANTS?.REGEX_NUMBER
  return validRegex.test(text)
}

const validPhone = text => {
  const validRegex = CONSTANTS?.REGEX_PHONE_NUMBER
  return validRegex.test(text)
}

const validText = text => {
  const validRegex = CONSTANTS?.REGEX_USER_TEXT
  return validRegex.test(text)
}

const validateEmail = email => {
  if (CONSTANTS.REGEX_EMAIL.test(email)) return true
  return false
}

const validPincode = text => {
  return text?.length === 6 ? true : false 
}

export { validNumber, validText, validateEmail, validPincode, validPhone }
